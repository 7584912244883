import { createTheme, getContrastRatio } from '@mui/material/styles';
import typography from './typography';
import colors from './colors';
import { fontWeight, lineHeight } from '@mui/system';

const _getContrastText = (color) =>
  getContrastRatio(color, colors.common.white) > 3 ? colors.common.white : colors.common.black;

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 1024,
    },
  },
  typography: {
    fontFamily: 'Toyota Type',
    display3: {
      fontFamily: 'Toyota Type',
      fontSize: '2rem',
      fontWeight: 300,
      lineHeight: '2.5rem',
      styleName: 'Display/Book',
    },
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h7: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.375rem',
    },
    h8: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '2rem',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    body3: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    body4: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    body5: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    body6: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: '#58595B',
    },
    body7: {
      fontSize: '0.9rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    body8: {
      fontSize: '0.9rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    body9: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
    },
    label1: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    label2: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      lineHeight: '1rem', // 16px
    },
    label3: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
      lineHeight: '1rem', // 16px
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    boldCaption: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    smallCaption: {
      fontSize: '0.5rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    hero: {
      fontSize: '3rem',
      fontWeight: 600,
    },
  },
  palette: {
    divider: colors.secondary[80],
    disabled: colors.secondary[80],
    familyDetails: {
      active: '#E9F6EC',
      needsAction: '#E66C02',
    },
    common: { ...colors.common },
    background: {
      default: colors.secondary[100],
      dark: colors.common.black,
      surfacePaper: colors.common.white,
    },
    form: {
      container: '#F8F8F9',
      containerHeader: '#F1F2F4',
      label: '#909295',
    },
    text: {
      primary: colors.common.black,
      secondary: colors.secondary[30],
      disabled: colors.secondary[50],
      white: colors.common.white,
      link: colors.info[50],
      error: colors.error[50],
    },
    primary: {
      main: colors.common.black,
      light: colors.secondary[80],
      dark: colors.secondary[20],
      contrastText: _getContrastText(colors.common.black),
    },
    secondary: {
      main: colors.secondary[10],
      light: colors.secondary[90],
      dark: colors.secondary[20],
      grey: colors.secondary[10],
      contrastText: _getContrastText(colors.secondary[10]),
      ...colors.secondary,
    },
    success: {
      main: colors.success[50],
      light: colors.success[80],
      dark: colors.success[30],
      contrastText: _getContrastText(colors.success[50]),
      ...colors.success,
    },
    warning: {
      main: colors.warning[50],
      light: colors.warning[80],
      dark: colors.warning[30],
      contrastText: _getContrastText(colors.warning[50]),
      ...colors.warning,
    },
    info: {
      main: colors.info[50],
      light: colors.info[100],
      dark: colors.info[20],
      contrastText: _getContrastText(colors.info[50]),
      ...colors.info,
    },
    error: {
      main: colors.error[50],
      light: colors.error[80],
      dark: colors.error[30],
      contrastText: _getContrastText(colors.error[50]),
      ...colors.error,
    },
    purple: { ...colors.purple },
    teal: { ...colors.teal },
    yellow: { ...colors.yellow },
    cyan: { ...colors.cyan },
    magenta: { ...colors.magenta },
    brand: {
      toyotaRed: '#eb0a1e', // NOT IN FULL TONAL PALETTE
      toyotaGray: '#58595b', // secondary30
      lexusGold: '#b6a171', // NOT IN FULL TONAL PALETTE
      lexusGray: '#adadad', // NOT IN FULL TONAL PALETTE
    },
    action: {
      disabledBackground: '#dbdcde',
      disabled: '#909295',
    },
    data: {
      darkTeal: '#004646', // NOT IN FULL TONAL PALETTE
      lightTeal: '#54aaaa', // NOT IN FULL TONAL PALETTE
      orange: '#ff832b', // NOT IN FULL TONAL PALETTE
      darkRed: '#8b0611', // NOT IN FULL TONAL PALETTE
      errorContainer: '#feebed', // NOT IN FULL TONAL PALETTE
      onErrorContainer: '#76050e', // NOT IN FULL TONAL PALETTE
      purple: '#5900d9', // NOT IN FULL TONAL PALETTE
    },
    status: {
      green: '#0E461A',
      backgroundGreen: '#E9F6EC',
      red: '#612D01',
      backgroundRed: '#FDF0E6',
      grey: '#909295',
    },
    supplementary: {
      yellow100: '#FEF9E8',
    },
    etaStatus: {
      ...colors.statusColors,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: typography,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: '2rem',
          paddingRight: '2rem',
          [theme.breakpoints.down('tablet')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        }),
        disableGutters: {
          padding: 0,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          borderColor: '#191A1C',
          borderRadius: '0.25rem',
        },
        outlined: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette[ownerState.color].dark,
            borderColor: theme.palette[ownerState.color].dark,
            color: _getContrastText(theme.palette[ownerState.color].dark),
          },
        }),
        text: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette[ownerState.color].light,
            color: _getContrastText(theme.palette[ownerState.color].light),
          },
        }),
        sizeSmall: {
          paddingTop: '0.45rem',
          paddingBottom: '0.45rem',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const styles = {
            borderRadius: '0.25rem',
            '& .MuiTouchRipple-root .MuiTouchRipple-child': {
              borderRadius: '0.25rem',
            },
          };

          if (ownerState.color !== 'inherit') {
            styles['&:hover'] = {
              backgroundColor: theme.palette[ownerState.color].light,
            };
          }

          return styles;
        },
      },
      defaultProps: {
        centerRipple: false,
        color: 'primary',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          padding: '0.25rem 0.5rem',
          height: '1.5rem',
        },
        label: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '3.5rem',
          zIndex: 1100,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: colors.common.black,
          paddingTop: '1.2rem',
          paddingBottom: '1.2rem',
        },
        inputRoot: {
          minHeight: '2.5rem',
          flexWrap: 'nowrap',
          '& .MuiOutlinedInput-input': {
            padding: '0 2rem 0 1rem',
            '&::placeholder': {
              color: colors.secondary[30],
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.secondary[50],
          },
          '&& > .MuiOutlinedInput-root': {
            paddingRight: 0,
          },
        },
        paper: {
          maxHeight: 'none !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colors.error[50],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          '&& > .MuiOutlinedInput-input': {
            padding: '0 2rem 0 1rem',
          },
        },
      },
    },
  },
});

export default theme;
