import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Box } from '@mui/material';
import FamilycodeProvider from 'context/FamilyCode.context';

// const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const KatashikiSetup = lazy(() => import('./pages/KatashikiSetup/KatashikiSetup'));
const FamilyCodeSetup = lazy(() => import('./pages/FamilyCodeSetup/FamilycodeSetup'));
const KatashikiReviewSpecDetails = lazy(() =>
  import('./pages/KatashikiSetup/KatashikiReviewSpecDetails'),
);

const Router = () => {
  const auth = useAuth();

  // eslint-disable-next-line
  switch (auth.activeNavigator) {
    case 'signoutRedirect':
    case 'signinSilent':
    case 'signinRedirect':
      return <div>Redirecting to Login...</div>;
  }

  if (auth.isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      sessionStorage.setItem('cube-pathname', window.location.pathname);
    }
    auth.signinRedirect();
    return null;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner fullScreen />}>
        <Routes>
          <Route path="/" element={<KatashikiSetup />} />
          <Route
            index
            path="/family-code-setup"
            element={
              <FamilycodeProvider>
                <FamilyCodeSetup />
              </FamilycodeProvider>
            }
          />
          <Route
            path="/katashiki-details/:FamilyCode/:Katashiki/:ModelYear"
            element={<KatashikiReviewSpecDetails />}
          />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
