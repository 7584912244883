// eslint-disable-next-line
export default {
  common: {
    white: '#ffffff',
    black: '#000000',
    transparent: '#00000000',
  },
  secondary: {
    100: '#f8f8f9',
    90: '#f1f2f4',
    80: '#dbdcde',
    70: '#b6b8bb',
    60: '#a7a8ab',
    50: '#909295',
    40: '#747578',
    30: '#58595b',
    20: '#303132',
    10: '#191a1c',
  },
  success: {
    100: '#e9f6ec',
    90: '#bae3c3',
    80: '#99d6a6',
    70: '#6bc37e',
    60: '#4eb865',
    50: '#22a63e',
    40: '#1f9738',
    30: '#18762c',
    20: '#135b22',
    10: '#0e461a',
  },
  warning: {
    100: '#fdf0e6',
    90: '#f7d1b1',
    80: '#f4bb8b',
    70: '#ee9d55',
    60: '#eb8935',
    50: '#e66c02',
    40: '#d16202',
    30: '#a34d01',
    20: '#7f3b01',
    10: '#612d01',
  },
  info: {
    100: '#e9f0ff',
    90: '#bbd1ff',
    80: '#9abaff',
    70: '#6c9bff',
    60: '#5087ff',
    50: '#2469ff',
    40: '#2160e8',
    30: '#1a4bb5',
    20: '#143a8c',
    10: '#0f2c6b',
    0: '#000d29',
  },
  error: {
    100: '#f9e6e8',
    90: '#edb2b7',
    80: '#e48d95',
    70: '#d75a64',
    60: '#d03946',
    50: '#c40818',
    40: '#b00716',
    30: '#9d0713',
    20: '#890713',
    10: '#52030a',
    0: '#feebed',
  },
  purple: {
    100: '#f2ebfc',
    90: '#d6c0f6',
    80: '#c2a1f1',
    70: '#a676eb',
    60: '#955ce7',
    50: '#7a33e1',
    40: '#6f2ecd',
    30: '#5724a0',
    20: '#431c7c',
    10: '#33155f',
    0: '#110523',
  },
  teal: {
    100: '#e7f3f2',
    90: '#b5dad6',
    80: '#91c9c2',
    70: '#5fb0a6',
    60: '#40a195',
    50: '#10897a',
    40: '#0f7d6f',
    30: '#0b6157',
    20: '#094b43',
    10: '#073a33',
    0: '#002929',
  },
  yellow: {
    100: '#fef9e8',
    90: '#fbecb8',
    80: '#f9e396',
    70: '#f6d666',
    60: '#f4ce49',
    50: '#f1c21b',
    40: '#dbb119',
    30: '#ab8a13',
    20: '#856b0f',
    10: '#65510b',
    0: '#261f02',
  },
  cyan: {
    100: '#e6f1f9',
    90: '#b0d3ec',
    80: '#8abee3',
    70: '#54a1d7',
    60: '#338ecf',
    50: '#0072c3',
    40: '#0068b1',
    30: '#00518a',
    20: '#003f6b',
    10: '#003052',
    0: '#001829',
  },
  magenta: {
    100: '#ffedf2',
    90: '#ffc6d5',
    80: '#ffaac1',
    70: '#ff84a5',
    60: '#ff6c93',
    50: '#ff4778',
    40: '#e8416d',
    30: '#b53255',
    20: '#8c2742',
    10: '#6b1e32',
    0: '#29000b',
  },
  statusColors: {
    onSchedule: {
      color: '',
      gradient:
        'linear-gradient(180deg, rgba(34, 166, 62, 0.1) 0%, rgba(233, 246, 236, 0.00) 100%)',
    },
    atRisk: {
      color: '',
      gradient:
        'linear-gradient(180deg, rgba(230, 108, 2, 0.1) 0%, rgba(233, 246, 236, 0.00) 100%)',
    },
    early: {
      color: '',
      gradient:
        'linear-gradient(180deg, rgba(111, 46, 205, 0.1) 0%, rgba(233, 246, 236, 0.00) 100%)',
    },
    delayed: {
      color: '',
      gradient: 'linear-gradient(180deg, rgba(196, 8, 24, 0.1) 0%, rgba(233, 246, 236, 0.00) 100%)',
    },
    etaUnavailable: {
      color: '',
      gradient:
        'linear-gradient(180deg, rgba(144, 146, 149, 0.1) 0%, rgba(233, 246, 236, 0.00) 100%)',
    },
  },
};
