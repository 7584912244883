/**
 * Context used for the Famly Code Setup Page.
 * Use this to manage any common data that has to be shared within the Family Code Setup components.
 * Reducer is used to dispatch the actions to update the context values.
 */
import PropTypes from 'prop-types';
import React, { createContext, useReducer } from 'react';

const initialState = {
  currentView: 'All Sub-Series',
  plantAssignments: null, // Initialize plantAssignments
  ssnCounts: {}, // ***#### Initialize ssnCounts
  familyCodeCounts: {}, // ***#### Initialize familyCodeCounts
};
export const FamilycodeContext = createContext(initialState);

export const familycodeReducer = (state, action) => {
  switch (action.type) {
    case 'CURRENT_VIEW':
      return {
        ...state,
        currentView: action.payload,
      };
    case 'SET_PLANT_ASSIGNMENTS':
      return {
        ...state,
        plantAssignments: action.payload,
      };
    case 'SET_SSN_COUNTS': // ***#### Handle SSN counts update
      return {
        ...state,
        ssnCounts: action.payload,
      };
    case 'SET_FAMILY_CODE_COUNTS': // ***#### Handle Family Code counts update
      return {
        ...state,
        familyCodeCounts: action.payload,
      };
    default:
      return state;
  }
};

export const FamilycodeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(familycodeReducer, initialState);

  const updateCurrentView = (value) => {
    dispatch({
      type: 'CURRENT_VIEW',
      payload: value,
    });
  };

  const updatePlantAssignments = (assignments) => {
    dispatch({
      type: 'SET_PLANT_ASSIGNMENTS',
      payload: assignments,
    });
  };
  // ***#### Function to update SSN counts
  const updateSsnCounts = (counts) => {
    dispatch({
      type: 'SET_SSN_COUNTS',
      payload: counts,
    });
  };

  // ***#### Function to update Family Code counts
  const updateFamilyCodeCounts = (counts) => {
    dispatch({
      type: 'SET_FAMILY_CODE_COUNTS',
      payload: counts,
    });
  };
  return (
    <FamilycodeContext.Provider
      value={{
        state,
        updateCurrentView,
        updatePlantAssignments,
        updateSsnCounts, // ***#### Expose setSsnCounts
        updateFamilyCodeCounts, // ***#### Expose setFamilyCodeCounts
      }}
    >
      {children}
    </FamilycodeContext.Provider>
  );
};
FamilycodeProvider.propTypes = { children: PropTypes.object };
export default FamilycodeProvider;
