import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Button, Snackbar, Stack, Typography, useTheme } from '@mui/material';
import {
  Info as InfoIcon,
  DoDisturbOn as DoDisturbOnIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import Link from '@mui/material/Link';

const ToastNotification = ({
  hideCloseButton,
  onClose,
  length,
  subtitle,
  title,
  type,
  open,
  horizontalPosition,
  verticalPosition,
  showLink = false,
  showCustomTitle = false,
  onLinkClick,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const getAlertProps = () => {
    const alertProps = {};
    if (!hideCloseButton) {
      alertProps.onClose = onClose;
    }
    // eslint-disable-next-line
    switch (type) {
      case 'info':
        alertProps.severity = 'info';
        alertProps.color = 'info';
        alertProps.icon = <InfoIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'success':
        alertProps.severity = 'success';
        alertProps.color = 'success';
        alertProps.icon = <CheckCircleIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'error':
        alertProps.severity = 'error';
        alertProps.color = 'error';
        alertProps.icon = <DoDisturbOnIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
      case 'warning':
        alertProps.severity = 'warning';
        alertProps.color = 'warning';
        alertProps.icon = <ErrorIcon sx={{ marginTop: '0.2rem' }} fontSize="small" />;
        break;
    }
    return alertProps;
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        horizontal: horizontalPosition,
        vertical: verticalPosition,
      }}
    >
      <Alert
        sx={{ borderLeft: `0.185rem solid ${theme.palette[type].main}`, maxWidth: '22rem' }}
        {...getAlertProps()}
      >
        <AlertTitle>
          <Typography component="label" variant="body1">
            {title}
          </Typography>
        </AlertTitle>
        <Stack alignItems="flex-start">
          <Typography component="label" variant="caption">
            {showCustomTitle
              ? subtitle
              : expanded || subtitle.length < length
              ? subtitle
              : `${subtitle.slice(0, length)}...`}
          </Typography>
          {subtitle.length > length && (
            <Button
              size="small"
              variant="text"
              color={type}
              onClick={() => setExpanded(!expanded)}
              sx={{
                padding: '0rem',
                minWidth: 'fit-content',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              disableRipple
            >
              <Typography component="span" variant="caption">
                {expanded ? 'Read Less' : 'Read More'}
              </Typography>
            </Button>
          )}
          {showLink && (
            <Link sx={{ cursor: 'pointer' }} color="#2469FF" onClick={onLinkClick} underline="none">
              <Typography component="span" variant="caption">
                Back to list view.
              </Typography>
            </Link>
          )}
        </Stack>
      </Alert>
    </Snackbar>
  );
};

ToastNotification.defaultProps = {
  hideCloseButton: false,
  length: 150,
  open: false,
  onClose: () => null,
};

ToastNotification.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
  horizontalPosition: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
  verticalPosition: PropTypes.oneOf(['bottom', 'top']).isRequired,
  open: PropTypes.bool.isRequired,
  hideCloseButton: PropTypes.bool,
  length: PropTypes.number,
  onClose: PropTypes.func,
  showLink: PropTypes.bool,
  onLinkClick: PropTypes.func,
  showCustomTitle: PropTypes.bool,
};

export default ToastNotification;
